import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    getAllReferralByAthleteId,
    reSendReward,
    createReward,
    getPlayer,
} from "../../../../API/roster.js";
import { useUserContext } from "../../../../contexts/ProviderProvider.jsx";
import useNotification from "../../../../hooks/useNotification.jsx";
import { Button } from "@mui/material";
import AreYouSure from "../../../common/AreYouSure.jsx";
import { useNavigate } from "react-router-dom";
import EditObject from "../../../common/EditObject";

const RewardedGiftCardTable = ({ athleteId }) => {
    const user = useUserContext();
    const notify = useNotification();

    const [rewards, setRewards] = useState([]);
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    const [confirmId, setConfirmId] = useState();
    const [isCreateRewardOpen, setIsCreateRewardOpen] = useState(false)

    useEffect(() => {
        if (isAccordionExpanded) {
            getAllReferralByAthleteId(user, athleteId).then((response) => {
                setRewards(response.data.obj);
            }).catch((error) => {
                notify(error.response?.data?.error || "Failed to get rewards");
            });
        }
    }, [isAccordionExpanded, user, athleteId, notify]);

    useEffect(() => {
        if (athleteId) {
            setIsAccordionExpanded(false)
        }
    }, [athleteId])

    const handleAccordionChange = () => {
        setIsAccordionExpanded(!isAccordionExpanded);
    };

    const toggleCreateModal = () => {
        setIsCreateRewardOpen(p => !p)
    }

    const resend = (id) => {
        reSendReward(id, user).then((response) => {
            notify({ msg: response.data.obj, variant: "success" });
            getAllReferralByAthleteId(user, athleteId).then((response) => {
                setRewards(response.data.obj);
            }).catch((error) => {
                notify(error.response?.data?.error || "Failed to get rewards");
            });
        }).catch((error) => {
            notify(
                error.response?.data?.error ||
                "Failed to resend reward",
            );
        });
    };

    const editObj = {
        rewardedAthleteAthleteId: athleteId,
        associatedAthleteRosterId: undefined,
        bonusType: "Referral",
        amount: 50.00
    }

    const handleSave = (changes) => {
        const reward = { ...editObj, ...changes }
        createReward(reward.rewardedAthleteAthleteId, reward.associatedAthleteRosterId, reward.bonusType, reward.amount, user)
            .then(response => {
                notify({ msg: "Success", variant: "success" })
                setIsCreateRewardOpen(false)
                getAllReferralByAthleteId(user, athleteId).then((response) => {
                    setRewards(response.data.obj);
                }).catch((error) => {
                    notify(error.response?.data?.error || "Failed to get rewards");
                });
            }).catch(err => {
                notify("Failed to create reward")
                setIsCreateRewardOpen(false)
                getAllReferralByAthleteId(user, athleteId).then((response) => {
                    setRewards(response.data.obj);
                }).catch((error) => {
                    notify(error.response?.data?.error || "Failed to get rewards");
                });
            })
    }

    const columns = [
        {
            name: "idRewardedGiftCard",
            label: "ID",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "rewardedAthlete",
            label: "Rewarded Athlete",
            options: {
                display: false,
                filter: true,
                sort: true,
            },
        },
        {
            name: "associatedAthlete",
            label: "Associated Athlete",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <StudentAthleteButton athleteId={value} />,
            },
        },
        {
            name: "reason",
            label: "Reason",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "shopifyGiftCardId",
            label: "Shopify Gift Card ID",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "isActive",
            label: "Active",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (value ? "Yes" : "No"),
            },
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => `$${value}`,
            },
        },
        {
            name: "remaining",
            label: "Remaining",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => `$${value}`,
            },
        },
        {
            name: "createdAt",
            label: "Created At",
            options: {
                filter: true,
                display: false,
                sort: true,
                customBodyRender: (value) => new Date(value).toLocaleString(),
            },
        },
        {
            name: "modifiedAt",
            label: "Modified At",
            options: {
                filter: true,
                display: false,
                sort: true,
                customBodyRender: (value) => new Date(value).toLocaleString(),
            },
        },
        {
            name: "resend",
            label: "Resend",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    // only display the button if the remaining is the same (or greater) than the amount
                    return tableMeta.rowData[7] >= tableMeta.rowData[6] && tableMeta.rowData[5]
                        ? (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setConfirmId(tableMeta.rowData[0]);
                                }}
                            >
                                Resend
                            </Button>
                        )
                        : <>Used or disabled</>;
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
        responsive: "standard",
        selectableRows: "none",
    };

    const editConfig = [
        {
            label: "Rewarded Athlete",
            type: "text",
            field: "rewardedAthleteAthleteId",
            style: { xs: 12, lg: 12 },
            can: false, // go to a different athlete if you want to change this one
        },
        {
            label: "Associated Athlete",
            type: "athleteselect",
            field: "associatedAthleteRosterId",
            style: { xs: 12, lg: 12 },
            can: true,
        },
        {
            label: "Reward Type",
            type: "select",
            selectOptions: ["Referral", "Signup"],
            field: "bonusType",
            style: { xs: 12, lg: 12 },
            can: true,
        },
        {
            label: "Amount",
            type: "text",
            field: "amount",
            style: { xs: 12, lg: 12 },
            isValid: (value) => {
                const num = parseFloat(value);
                if (!isNaN(num) && num.toString() === value.toString() && num % 1 !== 0) {
                    return true
                }
                return "Not a valid amount"
            }
        },
    ]

    return (
        <>
            <Accordion
                expanded={isAccordionExpanded}
                onChange={handleAccordionChange}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Rewarded Gift Cards
                </AccordionSummary>
                <AccordionDetails>
                    <MUIDataTable
                        title="Rewarded Gift Cards"
                        data={rewards}
                        columns={columns}
                        options={options}
                    />
                    <Button variant="contained" onClick={toggleCreateModal}> Create </Button>
                </AccordionDetails>
            </Accordion>
            <AreYouSure
                style={{
                    backgroundColor: "background.default",
                    margin: "auto",
                    marginTop: "10vh",
                    padding: "20px",
                    width: "30vw",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRadius: "5px",
                }}
                open={confirmId ? true : false}
                message={`Are you sure you want to resend this reward, the current gift card will be disabled ${confirmId}`}
                handleClose={() => setConfirmId(undefined)}
                yes={() => {
                    resend(confirmId);
                    setConfirmId(undefined);
                }}
                no={() => setConfirmId(undefined)}
            />
            <EditObject
                title={"Create Reward"}
                save={handleSave}
                cancel={toggleCreateModal}
                open={isCreateRewardOpen}
                config={editConfig}
                object={editObj}
                style={{
                    backgroundColor: "background.default",
                    margin: "auto",
                    marginTop: "10vh",
                    padding: "20px",
                    width: "60vw",
                    borderRadius: "5px",
                }}
            />
        </>
    );
};

const StudentAthleteButton = ({ athleteId }) => {
    const user = useUserContext()
    const notify = useNotification()
    const navigate = useNavigate()
    const [displayValue, setDisplayValue] = useState(athleteId);
    const [linkValue, setLinkValue] = useState(undefined)

    useEffect(() => {
        if (athleteId) {
            getPlayer(undefined, athleteId, undefined, user).then(response => {
                console.log(response.data)
                setDisplayValue(response.data.obj?.Roster?.full_name)
                setLinkValue(btoa(response.data.obj?.Roster?.idAthleteMerchInfo))
            }).catch(error => {
                notify("failed to hydrate referred users")
            })
        }
    }, [athleteId, notify, setDisplayValue, setLinkValue, user]);

    return (
        <Button
            onClick={linkValue ? () => navigate(`/ballpark/athletes/${linkValue}`) : undefined
            }>
            {displayValue}
        </Button >)
}

export default RewardedGiftCardTable;

