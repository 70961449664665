import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  ListItem,
  Checkbox,
  Autocomplete,
  Grid,
} from "@mui/material";
import React from "react";
import { AthleteProfileSearch } from "../modules/Athletes/AthleteProfileWrapper";

const getFieldFromObject = (object, path) => {
  if (!Array.isArray(path)) {
    return object[path]
  }

  for (let i = 0; i < path.length; i++) {
    if (!object) { break }
    object = object[path[i]]
  }
  return object
}

const EditObject = ({ title, save, cancel, open, config, object, style, confirmProductDeletion, setConfirmProductDeletion, handleClose }) => {
  const [alterations, setAlterations] = React.useState({});
  const [validations, setValidations] = React.useState({});

  const setAltered = (field, value) => {
    console.log("altering", field, value)
    setAlterations((prev) => {
      const updated = { ...prev };
      if (value !== object[field] && value !== undefined) {
        console.log(1)
        if (Array.isArray(field)) {
          field = field.join(".")
        }
        updated[field] = value;
      } else {
        console.log(2)
        delete updated[field];
      }
      console.log("finalized alterations", updated);
      return updated;
    });

    setValidations(p => {
      let prev = { ...p }
      prev[Array.isArray(field) ? field.join(".") : field] = validate(field, value)
      return prev
    })

  }

  const getValidation = (c) => {
    if (validations[Array.isArray(c.field) ? c.field.join(".") : c.field]) {
      return {
        key: c.field + "_validation",
        style: c.style,
        component: <p style={{ paddingLeft: "20px" }}>
          {validations[Array.isArray(c.field) ? c.field.join(".") : c.field]}
        </p>
      }
    }
  }

  const validate = (field, value) => {
    const fieldconfig = config.find((item) => { // find the config
      if (Array.isArray(item.field)) {
        return item.field.join(".") === field
      }
      return item.field === field
    })


    if (fieldconfig && fieldconfig.isValid) { // validate the field if possible
      return fieldconfig.isValid(value)
    }

    return undefined
  }

  const form = [];

  config.forEach((c) => {
    if (c.field === "confirmDeletion") {
      form.push({
        key: c.field,
        style: c.style,
        component: (
          <>
            <Checkbox
              label={c.label}
              defaultChecked={confirmProductDeletion}
              onChange={() =>
                setConfirmProductDeletion(!confirmProductDeletion)
              }
            />
            <span
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                color: "#f44336",
              }}
            >
              {c.label}
            </span>
          </>
        ),
      });
    } else if (c.type === "checkbox") {
      form.push({
        key: c.field,
        style: c.style,
        component: (
          <>
            <Checkbox
              label={c.label}
              defaultChecked={object[c.field]}
              disabled={!c.can}
              onChange={() => setAltered(c.field, !object[c.field])}
            />
            <span>{c.label}</span>
          </>
        ),
      });
    } else if (c.type === "text") {
      const v = getValidation(c)

      form.push({
        key: c.field,
        style: c.style,
        component: (
          <div>
            <TextField
              sx={{ width: "100%", ...c.style }}
              label={c.label}
              disabled={!c.can}
              defaultValue={getFieldFromObject(object, c.field)}
              onChange={(e) => setAltered(c.field, e.target.value)}
            />
            <Typography sx={{ color: "error.main" }}>
              {v?.component}
            </Typography>
          </div>
        ),
      });
    } else if (c.type === "select") {
      form.push({
        key: c.field,
        style: c.style,
        component: (
          <Autocomplete
            label={"here"}
            fullWidth
            sx={{ maxWidth: "100%", width: "100%", ...c.style }}
            options={c.selectOptions}
            value={
              alterations[c.field]
                ? alterations[c.field]
                : object[c.field]
            }
            onChange={(e, v) => setAltered(c.field, v)}
            disabled={!c.can}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} label={c.label} />}
            renderOption={(props, option, state) => (
              <ListItem
                {...props}
                key={option}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid white",
                }}
              >
                <div>{option}</div>
              </ListItem>
            )}
          />
        ),
      });
    } else if (c.type === "athleteselect") {
      form.push({
        key: c.field,
        style: c.style,
        component: (
          <AthleteProfileSearch rosterId={alterations[c.field]} onSelect={(rosterId) => setAltered(c.field, rosterId)} />
        ),
      });
    }
  });


  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container sx={style}>
            <Grid item lg={12} xs={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {title}
              </Typography>
            </Grid>
            {form.map((f) => (
              <Grid
                item
                key={f.key}
                style={{ paddingBottom: "10px" }}
                {...f.style}
              >
                {f.component}
              </Grid>
            ))}
            <Grid
              item
              lg={12}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <span>
                <Button
                  sx={{
                    margin: "10px",
                    backgroundColor: "success.main",
                    color: "White",
                  }}
                  onClick={() => {
                    save(alterations);
                    setAlterations({});
                  }}
                >
                  {" "}
                  Save
                </Button>
              </span>
              <span>
                <Button
                  sx={{
                    margin: "10px",
                    backgroundColor: "error.main",
                    color: "White",
                  }}
                  onClick={cancel}
                >
                  Cancel
                </Button>
              </span>
            </Grid>
          </Grid>
        </Modal>
      )
      }
    </>
  );
};

export default EditObject;

