import * as React from "react";
import { useUserContext } from "../../../../contexts/ProviderProvider";
import { getProductCount } from "../../../../API/products";
import { getUniversityProductCount } from "../../../../API/products";
import useNotification from "../../../../hooks/useNotification";

import { CircularProgress, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";

const GrandSlam = ({ addToCardArray, setLoading }) => {
    const [currentlyRunning, setCurrentlyRunning] = React.useState([]);
    const [refresh, setRefresh] = React.useState(0);

    const [loadingData, setLoadingData] = React.useState(true);
    const [universityCount, setUniversityCount] = React.useState([]);

    const notify = useNotification();
    const user = useUserContext();

    const updateUniversityCount = (results) => {
        var temp = {};
        const product_results = results["products"];
        for (let result in product_results) {
            const result_university = product_results[result]["schoolName"];
            const count = product_results[result]["selectedCount"];
            if (result_university != undefined) {
                temp[result_university] = [count, 0];
            }
        }
        const collection_results = results["collections"];
        for (let result in collection_results) {
            const result_university = collection_results[result]["schoolName"];
            const count = collection_results[result]["selectedCount"];
            if (result_university != undefined) {
                if (temp[result_university] !== undefined) {
                    temp[result_university] = [
                        temp[result_university][0],
                        count,
                    ];
                } else {
                    temp[result_university] = [0, count];
                }
            }
        }

        const temp_arr = Object.entries(temp).map(([key, value]) => {
            return {
                university: key,
                product_count: value[0],
                collection_count: value[1],
            };
        });
        setUniversityCount(temp_arr);
    };

    const updateCurrentlyRunning = React.useCallback(
        (newVal) => {
            if (newVal.length !== currentlyRunning.length) {
                setCurrentlyRunning(newVal);
                setRefresh((p) => p + 1);
            }
        },
        [currentlyRunning],
    );

    React.useEffect(() => {
        setLoadingData(true);
        setLoading(true);
        getProductCount(user).then((response) => {
            addToCardArray({
                id: "Grand Slam",
                panel: "GrandSlam",
                count: response.data.obj | 0,
            });
        }).catch((error) => {
            notify("Failed to  get product count");
        });
        getUniversityProductCount(user)
            .then((response) => {
                updateUniversityCount(response.data);
                setLoadingData(false);
                setLoading(false);
            })
            .catch((error) => {
                notify("Failed to get product university counts");
                setLoading(false);
                setLoadingData(false);
            });
    }, [user, updateCurrentlyRunning, refresh, notify]);

    const columns = [
        {
            name: "product_count",
            label: "Product Count",
        },
        {
            name: "collection_count",
            label: "Collection Count",
        },
        {
            name: "university",
            label: "University",
        },
    ];

    const options = {
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: loadingData ? <CircularProgress /> : (
                    "No Records"
                ),
            },
        },
    };

    return (
        <div
            style={{
                margin: "20px 10px",
            }}
        >
            {universityCount
                ? (
                    <MUIDataTable
                        data={universityCount}
                        columns={columns}
                        options={options}
                    />
                )
                : (
                    <Typography variant="h6">
                        Data not found for Grand Slam
                    </Typography>
                )}
        </div>
    );
};

export default GrandSlam;
