import { useEffect, useState } from "react";
import {
  Typography,
  Autocomplete,
  LinearProgress,
  TextField,
  ListItem,
} from "@mui/material";
import { useUserContext } from "../../../contexts/ProviderProvider.jsx";
import AthleteProfile from "./AthleteProfile.jsx";
import { searchRosterForName } from "../../../API/roster.js";
import Roster from "./Roster.jsx";
import { useNavigate, useParams } from "react-router-dom";
import useNotification from "../../../hooks/useNotification.jsx";
import { decodeURLVariable } from "../../../utilities/utils.js";

const AthleteProfileWrapper = (props) => {
  const { rosterIdEncoded, schoolEncoded } = useParams();

  console.log("rosterId-start", rosterIdEncoded)

  const notify = useNotification();
  const user = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    const decodedRosterId = decodeURLVariable(rosterIdEncoded);

    // Update selectedMerchRoster if the URL changes
    if (decodedRosterId) {
      navigate("/ballpark/athletes/" + btoa(decodedRosterId));
    }
  }, [rosterIdEncoded, navigate]);

  if (schoolEncoded) {
    return (
      <Roster school={schoolEncoded ? atob(schoolEncoded) : schoolEncoded} />
    );
  }

  if (!user) {
    return <></>;
  }

  console.log("rosterId", rosterIdEncoded)

  return (<>
    <div style={{ width: "80%", paddingTop: "5vh" }}>
      <div style={{ width: "50%", margin: "auto" }}>
        <AthleteProfileSearch
          rosterId={decodeURLVariable(rosterIdEncoded)}
          onSelect={(rosterId) => navigate(`/ballpark/athletes/${btoa(rosterId)}`)}
        />
      </div>
    </div>
    {notEmpty(rosterIdEncoded) && <AthleteProfile rosterId={decodeURLVariable(rosterIdEncoded)} />}
  </>)
};

export const AthleteProfileSearch = ({ rosterId, onSelect }) => {
  const user = useUserContext()
  const notify = useNotification()

  const [searchMatches, setSearchMatches] = useState([]);
  const [input, setInput] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const fetchUsers = (event, value) => {
    if (searchMatches.length > 0) {
      setSearchMatches([]);
    }
    setInput(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log("rosterId search", { searchLoading, input, searchMatches, rosterId })
      if (
        !searchLoading &&
        input?.length >= 2 &&
        searchMatches.length === 0
      ) {
        setSearchLoading(true);
        searchRosterForName(input, user)
          .then((response) => {
            if (response.data.length === 1) {
              setInput(undefined)
              onSelect(response.data[0].idAthleteMerchInfo)
            }
            setSearchMatches(response.data);
            setSearchLoading(false);
          })
          .catch((error) => {
            console.error(error);
            notify("Failed to complete search");
            setSearchMatches([]);
            setSearchLoading(false);
          });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchLoading, input, searchMatches]);

  return (
    <>
      {searchLoading && <LinearProgress />}
      <Autocomplete
        value={undefined}
        options={searchMatches}
        onInputChange={fetchUsers}
        onChange={(e, v) => {
          onSelect(v?.idAthleteMerchInfo)
        }}
        getOptionLabel={(option) => option.full_name}
        renderInput={(params) => <TextField {...params} label="Athlete Search" />}
        renderOption={(props, option, state) => (
          <ListItem
            {...props}
            key={option.idAthleteMerchInfo}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid white",
              backgroundColor: decideSearchOptionBackgroundColor(option)
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              {option.jersey_number}
            </div>
            <div style={{ flex: 6 }}>{option.full_name}</div>
            <div
              style={{
                flex: 4,
              }}
            >
              {option.sport}
            </div>
            <div
              style={{
                flex: 3,
              }}
            >
              {option.university}
            </div>
          </ListItem>
        )}
      />
    </>
  );
}

const decideSearchOptionBackgroundColor = (option) => {
  if (option.is_transfer) {
    return "warning.main"
  }

  if (option.signed) {
    return "background.default"
  }

  return "error.main"
}

const notEmpty = (param) => {
  if (!param) {
    return false
  }

  if (param.includes(":")) {
    return false
  }
  return true
}

export default AthleteProfileWrapper;
