import axios from "axios";

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;
const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL




export const getSchools = (user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  };

  return axios.get(BASE_HATTRICK_URL + "allSchools", config);
};

export const getSchoolByName = (school, user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  };

  // return axios.post(BALLPARK_BACKEND_BASE_URL + "getSchoolByName", { schoolName: school }, config);
  return axios.post(BASE_HATTRICK_URL + "getSchoolByName", { schoolName: school }, config);

};

export const updateSchool = (schoolName, field, value, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(
    BALLPARK_BACKEND_BASE_URL + "updateSchool",
    { schoolName, field, value },
    { headers }
  );
};

export const createSchool = (school, user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  };

  return axios.post(BALLPARK_BACKEND_BASE_URL + "school", school, config);
};

export const getSchoolsMeta = (user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
  };

  return axios.get(BALLPARK_BACKEND_BASE_URL + "allSchoolMeta", config);
};

export const getProductMetadataBySchool = (school, user) => {

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  }
  return axios.get(BALLPARK_BACKEND_BASE_URL + "/getProductMetadataBySchool?" + (school ? "uni=" + btoa(school) : ""), { headers })

}