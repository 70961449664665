import {useState, useEffect} from "react";
import { Typography } from "@mui/material";

import { useUserContext } from "../../../contexts/ProviderProvider";
import {
  getUnMatchedRosters,
  getMerchRosterBySchool,
} from "../../../API/roster";
import MatchingTables from "./MatchingTables";
import { decompressData } from "../../../utilities/utils";

const Matcher = (props) => {
  const school = props.school.university;
  const [loadingUnMatchedRosterList, setLoadingUnMatchedRosterList] = useState(false);
  const [unMatchedRosterList, setUnMatchedRosterList] = useState([]);
  const [loadingRosterList, setLoadingRosterList] = useState(false);
  const [rosterList, setRosterList] = useState([]);
  const [forceReload, setForceReload] = useState(1);

  const user = useUserContext();

  useEffect(() => {
    if (!user) {
      return;
    }
    setLoadingUnMatchedRosterList(true);
    setLoadingRosterList(true);
    getUnMatchedRosters(school, user)
      .then((response) => {
        setUnMatchedRosterList(response.data.roster);
        setLoadingUnMatchedRosterList(false);
      })
      .catch((error) => {
        console.error(error);
        setUnMatchedRosterList([]);
        setLoadingUnMatchedRosterList(false);
      });
    getMerchRosterBySchool(school, user)
      .then((response) => {
        // const responseData = decompressData(response);
        // const responseData = response;

        setRosterList(response.data.roster);
        setLoadingRosterList(false);
      })
      .catch((error) => {
        console.error(error);
        setRosterList([]);
        setLoadingRosterList(false);
      });
  }, [forceReload, user, school]);

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h1">Matcher</Typography>
      <div>
        <span
          style={{
            opacity: "0.4",
            fontSize: "14",
            marginLeft: "0px",
            position: "relative",
            top: "-10px",
          }}
        >
          Roster for {props.school.university}
        </span>
        <MatchingTables
          unmatched={unMatchedRosterList.sort((a, b) => (a.full_name || '').localeCompare(b.full_name || ''))}
          rosterList={rosterList}
          loadingUnMatched={loadingUnMatchedRosterList}
          loadingRoster={loadingRosterList}
          reload={() => {
            setForceReload((p) => p + 1);
          }}
        />
      </div>
    </div>
  );
};

export default Matcher;
